const HtmlSignature = ({signature}) => {
    return ( 
        <div>
            <div className="mt-3 mb-2">
                <textarea className="form-control" id="htmlSignature" rows="6" value={signature} readOnly>
                </textarea>
            </div>           
        </div>
    );
}
 
export default HtmlSignature;