import React, { useState } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { FaGoogle } from "react-icons/fa";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const GoogleBtn = ({ profile, setProfile }) => {
  const [error, setError] = useState("");

  const login = (response) => {
    if (response.accessToken) {
      setProfile({
        isLogined: true,
        accessToken: response.accessToken,
      });
      setError("");
    }
  };

  const logout = (response) => {
    setProfile({
      isLogined: false,
      accessToken: "",
    });
  };

  const handleLoginFailure = (response) => {
    if (response.error !== "idpiframe_initialization_failed") {
      setError("Failed to log in");
    }
  };

  const handleLogoutFailure = (response) => {
    setError("Failed to log out");
  };

  return (
    <div>
      {error.length > 0 && <p className="text-danger text-center">{error}</p>}
      {profile.isLogined ? (
        <GoogleLogout
          clientId={CLIENT_ID}
          onLogoutSuccess={logout}
          onFailure={handleLogoutFailure}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="btn btn-outline-dark btn-block"
            >
              <div className="d-flex justify-content-center align-items-center">
                <span>Log out</span>
              </div>
            </button>
          )}
        ></GoogleLogout>
      ) : (
        <GoogleLogin
          clientId={CLIENT_ID}
          onSuccess={login}
          onFailure={handleLoginFailure}
          cookiePolicy={"single_host_origin"}
          responseType="code,token"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="btn btn-outline-dark btn-block"
            >
              <div className="d-flex justify-content-center align-items-center">
                <FaGoogle className="mr-2" style={{ fontSize: "1.5rem" }} />
                <span>Log in with Google</span>
              </div>
            </button>
          )}
        />
      )}
    </div>
  );
};

export default GoogleBtn;
