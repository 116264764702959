import { useState } from "react";
import "./App.css";
import Form from "./components/form";
import HtmlSignature from "./components/HtmlSignature";
import Preview from "./components/preview";
import GoogleBtn from "./components/GoogleBtn";

function App() {
  const [copied, setCopied] = useState(false);
  const emailDomain = "@bloomsybox.com";
  const [info, setInfo] = useState({
    name: "",
    position: "",
    email: emailDomain,
  });
  const [profile, setProfile] = useState({
    isLogined: false,
    accessToken: "",
  });
  const [generateSignature, setGenerateSignature] = useState(false);
  const [signature, setSignature] = useState("");
  const [tab, setTab] = useState("preview");
  const [error, setError] = useState(false);

  const copyCodeToClipboard = () => {
    setCopied(true);
    setTimeout(() => {
      const range = document.createRange();
      range.selectNode(
        document.querySelector(
          tab === "preview" ? "#preview" : "#htmlSignature"
        )
      );
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="container col-12 col-lg-5 mt-3 mb-1">
      <div className="d-flex flex-column justify-content-center mt-5">
        <img
          className="rounded mx-auto d-block "
          src={process.env.PUBLIC_URL + "logo.png"}
          alt="Bloomsybox Logo"
          style={{ width: "100px" }}
        />
        <h2 className="text-center mt-3">Email Signature Builder</h2>
        <div className="mx-auto mt-3">
          <GoogleBtn profile={profile} setProfile={setProfile} />
        </div>
      </div>
      {profile.isLogined && (
        <div>
          {error ? (
            <div className="alert alert-danger mt-2" role="alert">
              All the fields are required.
            </div>
          ) : null}
          <Form
            info={info}
            setInfo={setInfo}
            setGenerateSignature={setGenerateSignature}
            setError={setError}
            setSignature={setSignature}
            generateSignature={generateSignature}
            emailDomain={emailDomain}
            tab={tab}
          />
          {generateSignature && (
            <div className="mt-4">
              <ul
                className="nav nav-tabs flex-sm-row"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item flex-sm-fill" role="presentation">
                  <a
                    className="nav-link active"
                    id="preview-tab"
                    data-toggle="tab"
                    href="#preview"
                    role="tab"
                    aria-controls="preview"
                    aria-selected="true"
                    onClick={() => setTab("preview")}
                  >
                    Normal Output
                  </a>
                </li>
                <li className="nav-item flex-sm-fill" role="presentation">
                  <a
                    className="nav-link"
                    id="output-tab"
                    data-toggle="tab"
                    href="#output"
                    role="tab"
                    aria-controls="output"
                    aria-selected="false"
                    onClick={() => setTab("output")}
                  >
                    Raw Output
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="preview"
                  role="tabpanel"
                  aria-labelledby="preview-tab"
                >
                  <Preview signature={signature} />
                </div>
                <div
                  className="tab-pane fade"
                  id="output"
                  role="tabpanel"
                  aria-labelledby="output-tab"
                >
                  <HtmlSignature signature={signature} />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-end mb-5">
                <button
                  onClick={() => copyCodeToClipboard()}
                  className="btn btn-outline-primary ml-2 mr-2"
                >
                  Copy to Clipboard
                </button>
                {copied && (
                  <span className="block pr-2 mt-2" style={{ color: "green" }}>
                    Copied!
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      )}{" "}
    </div>
  );
}

export default App;
