const Preview = ({signature}) => {
    
    return ( 
        <div className="row mx-0">            
            <div
                style={{paddingRight: "0px", paddingLeft: "0px", paddingTop: "30px", paddingBottom: "30px"}}
            >
                <div 
                    dangerouslySetInnerHTML={{__html: signature}} 
                    id="preview"
                >
                </div>
            </div>
        </div>
    );
}
 
export default Preview;