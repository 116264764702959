import { Fragment } from "react";
import { getFirstName } from "./../helper";

const Form = ({
  info,
  setInfo,
  setGenerateSignature,
  setError,
  setSignature,
  generateSignature,
  emailDomain,
  tab,
}) => {
  const { name, position, email } = info;
  const handleChange = (e) => {
    if (e.target.name === "name") {
      setInfo({
        ...info,
        [e.target.name]: e.target.value,
        email: `${getFirstName(
          e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        )}${emailDomain}`,
      });
    } else {
      setInfo({
        ...info,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim() === "" || position.trim() === "" || email.trim() === "") {
      setError(true);
      return;
    }
    setSignature(`<table cellspacing="0" cellpadding="0" border="0">
            <tbody>
              <tr>
                <td style="padding-top: 0px; padding-right: 10px; padding-left: 10px; border-right: 1px solid #53565c" width="67" valign="top">
                  <img src="https://storage.googleapis.com/bloomsybox-assets/email/bloomsybox-logo.png" nosend="1" alt="BloomsyBox Logo" width="67" height="75" border="0" style="vertical-align: top;">
                </td>
                <td style="padding-left: 0px; padding-top: 0px;" valign="top">
                  <table cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                      <tr>
                        <td align="left" style="font-size:0px;padding-top:0px;padding-left:5px;word-break:break-word;">
                          <div style="font-family: Arial; color: #646464;font-size:12px; line-height:1; text-align:left">
                            <p style="margin-top:2px; margin-bottom:0px; font-weight:bold">${name}</p>
                            <p style="margin-top:2px; margin-bottom:10px">${position}, <a href="https://www.bloomsybox.com" style="text-decoration: underline;">BloomsyBox.com</a></p>
                            <p style="margin-top:0; margin-bottom:2px">+1 877-422-1316 | ${email.toLowerCase()}</p>
                            <p style="margin-top:0;  margin-bottom:0">2200 NW 70th Ave, Miami, FL 33122</p>
                          </div>
                          <div style="margin-top: 10px;">
                            <table border="0" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr style="padding-top: 10px;">
                                  <td style="padding-right: 5px;" align="left"><a href="https://facebook.com/Bloomsybox" target="_blank" rel="noopener"><img src="https://s3.amazonaws.com/images.wisestamp.com/social_icons/square/facebook.png" width="16" height="16" /></a></td>
                                  <td style="padding-right: 5px;" align="left"><a href="https://twitter.com/Bloomsybox" target="_blank" rel="noopener"><img src="https://s3.amazonaws.com/images.wisestamp.com/social_icons/square/twitter.png" width="16" height="16" /></a></td>
                                  <td style="padding-right: 5px;" align="left"><a href="https://instagram.com/Bloomsybox" target="_blank" rel="noopener"><img src="https://s3.amazonaws.com/images.wisestamp.com/social_icons/square/instagram.png" width="16" height="16" /></a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>`);
    setError(false);
    setGenerateSignature(true);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <form className="mt-2" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={name}
                placeholder="Your full name"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="position">Job Title</label>
              <input
                type="text"
                className="form-control"
                id="position"
                name="position"
                value={position}
                placeholder="Your job title"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                Generate
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
